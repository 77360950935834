.dropdown-wrapper {
  position: relative;
}

.dropdown {
  position: absolute;
  top: 0;
  left: 0;
  width: max-content; /* Adjust width as needed */
  z-index: 1000; /* Ensure it is above other elements */
  display: block;
  visibility: visible; /* Ensure it is visible when needed */
}
