
@import "../../assets/scss/mixin.scss";

.schedule {
  // margin-top: 150px;
  overflow-x: scroll;
}

.schedule th, 
.schedule tr, 
.schedule td {
  border: 1px solid black !important;
}

.bg-green {
  background-color: #99ff99;
}

.bg-pink {
  background-color: #ff9999;
}

.bg-yellow {
  background-color: #ffff67;
}

.bg-light-blue {
  background-color: rgb(141, 164, 207);
}

.bg-blue {
  background-color: #91b0df;
}

.custom-red {
  color: red;

}

.schedule .bdr-color {
  border: 2px solid rgb(177, 220, 255) !important;
}

.schedule .bdr-btn-color {
  // border-bottom: 2px solid rgb(177, 220, 255) !important;
}

.schedule .border-silver {
  border-right: 2px solid rgb(177, 220, 255) !important;
}

.schedule .main-heading {
  /* color: white; */
  width: 100%;
}

.schedule .table-light {
  color: #000;
}



.schedule td {
  vertical-align: middle !important;
  text-align: center !important;
}

.schedule .heading {
  font-size: 13px;
  width: 150px;
  height: 50px;
  background-color:#fff;
  color: black;
  
}

.schedule .heading-1 {
  font-size: 19px;
  font-weight: 500;
  height: 40px;
  background-color: #fff;
  color: black
  
}

.schedule .heading-date {
  font-size: 12px;
  background-color: rgb(192, 190, 190);
}

.schedule td {
  font-size: 10px;
  font-weight: 900;

}


// Custom ToolTip CSS


#custom-tooltip{
  opacity: 1;
  background-color: #fff;
}


.tooltip-inner {
  background-color: white !important; /* Custom background color */
  color:#000 !important; /* Custom text color */
  // font-size: 14px !important; /* Adjust font size */
  // border-radius: 8px !important; /* Optional: Rounded corners */
  min-width: 500px !important;
  height: 400px !important;
  display: table-cell;
  
  
}

.tooltip-arrow {
  border-top-color: #ff5733 !important; /* Customize the tooltip arrow */
}

#custom-tooltip .main{
  height:100% !important;
  width:500px !important;
  text-align: left;
  
}
#custom-tooltip .main h3{
  font-weight: 700;
}


// holiday Action Box
.holiday-leave{
  display: flex;
  justify-content:space-evenly;
  align-items: center;
  // column-gap: 23px;
}

.reason{
  width: 20%;
}
.date{
  width: 60%;
}
.holiday{
  width: 6%;
}

.actionBar{
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
}

.actionBar .actionHeader{
  background-color: #91b0df;
  padding: 4px 10px;
  text-align: center;
}

.actionBar .actionHeader p{
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 0;
}

.actionBar .actionHeader p span{
  font-weight: 600;
}

.actionBar .actionBody{
  padding: 15px 5px;
}


.disabled-time {
  opacity: 0.5; 
  pointer-events: none; 
}

.disabled-time:hover {
  cursor: not-allowed; 
}