.inventory-container {
  padding: 20px;
  font-family: "Poppins", sans-serif;

  h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .material-form {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;

    input {
      flex: 1;
      padding: 10px;
      font-size: 16px;
      border: 2px solid #ddd;
      border-radius: 5px;
    }

    .add-material-btn {
      background-color: #007bff;
      color: white;
      border: none;
      padding: 10px 15px;
      font-size: 16px;
      font-weight: bold;
      border-radius: 5px;
      cursor: pointer;
      transition: 0.3s ease;

      &:hover {
        background-color: #0056b3;
      }
    }
  }

  .material-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;

    thead {
      background-color: #007bff;
      color: white;
      font-weight: bold;
    }

    th, td {
      border: 1px solid #ddd;
      padding: 10px;
      text-align: left;
    }

    tbody tr:nth-child(even) {
      background-color: #f8f9fa;
    }

    .delete-btn {
      background-color: #dc3545;
      color: white;
      border: none;
      padding: 5px 10px;
      font-size: 14px;
      font-weight: bold;
      border-radius: 5px;
      cursor: pointer;
      transition: 0.3s ease;

      &:hover {
        background-color: #a71d2a;
      }
    }
  }
}
.inventory-container {
  padding: 20px;
  font-family: "Poppins", sans-serif;

  h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .warehouse-form {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;

    input {
      flex: 1;
      padding: 10px;
      font-size: 16px;
      border: 2px solid #ddd;
      border-radius: 5px;
    }

    .add-warehouse-btn {
      background-color: #007bff;
      color: white;
      border: none;
      padding: 10px 15px;
      font-size: 16px;
      font-weight: bold;
      border-radius: 5px;
      cursor: pointer;
      transition: 0.3s ease;

      &:hover {
        background-color: #0056b3;
      }
    }
  }

  .warehouse-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;

    thead {
      background-color: #007bff;
      color: white;
      font-weight: bold;
    }

    th, td {
      border: 1px solid #ddd;
      padding: 10px;
      text-align: left;
    }

    .no-data {
      text-align: center;
      font-style: italic;
      color: #777;
    }

    .warehouse-link {
      color: #007bff;
      font-weight: bold;
      text-decoration: none;
      transition: 0.3s ease;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
