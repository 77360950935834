@import "../../assets/scss/mixin.scss";

.selfControls {
  flex: 0 0 10%; 
  padding: 3px;
  border: 1px solid gray;

}
.selfControls:nth-child(n+4) {
    border-top: none;
}